import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import en from '@shared/i18n/en.translation';
import es from '@shared/i18n/es.translation';
import { RoutePartsService } from '@shared/services/route-parts.service';
import 'moment/locale/es';
import { filter } from 'rxjs';

//texto en color negro cuando los campos estan desactivados, ajustado reponsib de dialogos, color de selector de ag grid en ceco y item ptpc

export const translations = [
  {
    key: 'en',
    displayValue: 'English',
    value: en,
    localDate: localeEn,
  },
  {
    key: 'es',
    displayValue: 'Español',
    value: es,
    localDate: localeEs,
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  appTitle = '';
  pageTitle = '';
  constructor(
    private readonly translate: TranslateService,
    private readonly adapter: DateAdapter<Date>,
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService
  ) {}

  ngOnInit(): void {
    translations.forEach(({ key, value, localDate }) => {
      this.translate.setTranslation(key, value);
      registerLocaleData(localDate, key);
    });

    const browserLang = this.translate.getBrowserLang() ?? '';

    this.translate.setDefaultLang(
      translations.some(i => i.key === browserLang)
        ? browserLang
        : translations[0].key
    );

    this.changePageTitle();
    this.translate.onLangChange.subscribe(() => {
      this.changeTitle();
    });

    this.translate.onDefaultLangChange.subscribe(() => {
      this.changeTitle();
    });
  }

  changePageTitle() {
    this.appTitle = this.translate.instant('TITLE');
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.changeTitle();
      });
  }

  changeTitle() {
    this.appTitle = this.translate.instant('TITLE');
    const routeParts = this.routePartsService.generateRouteParts(
      this.activeRoute.snapshot
    );
    if (!routeParts.length) return this.title.setTitle(this.appTitle);
    // Extract title from parts;
    this.pageTitle = routeParts
      .reverse()
      .map(part => part.title)
      .reduce((partA, partI) => {
        const partATranslated = this.translate.instant('PAGES_TITLES.' + partA);
        const partITranslated = this.translate.instant('PAGES_TITLES.' + partI);
        return `${partATranslated} > ${partITranslated}`;
      });
    this.pageTitle += ` | ${this.appTitle}`;
    this.title.setTitle(this.pageTitle);
  }
}
