const translations = {
  TITLE: 'Ofertas Web',
  PAGES_TITLES: {
    SESSIONS: 'Sesiones',
    DASHBOARD: 'Tablero',
    PROFILE: 'Perfil',
    REQUESTS: 'Solicitudes',
    QUOTES: 'Ofertas',
    ORDERS: 'Pedidos',
    REQUESTS_LIST: 'Lista',
    REQUESTS_EDIT: 'Editar',
    SESSIONS_SIGNIN: 'Iniciar sesión',
    SESSIONS_CHANGE_PASSWORD: 'Cambiar contraseña',
    PROFILE_SETTINGS: 'Configuración',
    QUOTES_LIST: 'Lista',
    QUOTES_DETAIL: 'Detalle',
    ORDERS_LIST: 'Lista',
    ORDERS_HISTORY: 'Histórico',
    ORDERS_DETAIL: 'Detalle',
    ORDERS_INVOICES_AND_CREDITS: 'Facturas y abonos',
    ORDERS_LINES_LIST: 'Lista de líneas',
  },
  ENUMS: {
    REQUEST_DOCUMENT_STATUS: {
      DRAFT: 'Borrador',
      SENT: 'Enviada',
      ACCEPTED: 'Aceptada',
    },
    REQUEST_DOCUMENT_TYPE: {
      NORMAL: 'Normal',
      URGENT: 'Urgente',
      VERY_URGENT: 'Muy Urgente',
    },
    QUOTE_DOCUMENT_STATUS: {
      PENDING_SHIPPING: 'Pendiente de envío',
      PENDING_CLARIFICATION: 'Pendiente de aclarar',
      PENDING_CONFIRMATION: 'Pendiente de confirmar',
      PARTIALLY_ACCEPTED: 'Aceptada parcialmente',
      ACCEPTED: 'Aceptada',
      REJECTED: 'Rechazada',
      ANNULLED: 'Anulada',
    },
    QUOTE_APPROVAL_STATUS: {
      PRELIMINARY_REVIEW: 'Revisión preliminar',
      NOT_APPROVAL_NEEDED: 'No necesita aprobación',
      PENDING: 'Pendiente',
      APPROVED: 'Aprobada',
      NOT_APPROVED: 'No aprobada',
    },
    LINE_STATUS: {
      PENDING_CLARIFICATION: 'Pendiente de aclarar',
      PENDING_CONFIRMATION: 'Pendiente de confirmar',
      ACCEPTED: 'En pedido',
      REJECTED: 'Anulada',
      ACCEPTED_FROM_PORTAL: 'Aceptada',
      REJECTED_FROM_PORTAL: 'Rechazada',
    },
    ORDER_DOCUMENT_STATUS: {
      PENDING_SHIPPING: 'Pendiente de envío',
      PENDING_CLARIFICATION: 'Pendiente de aclarar',
      SERVE_PENDING: 'Pendiente de servir',
      DELIVERED: 'Entregado',
      PARTIALLY_DELIVERED: 'Entregado parcialmente',
      SHIPPED: 'Enviado',
      PARTIALLY_SHIPPED: 'Enviado parcialmente',
      INVOICED: 'Facturado',
    },
  },
  SNACKBAR: {
    ERROR: 'Error',
    SUCCESS: 'Éxito',
    WARNING: 'Advertencia',
    MESSAGES: {
      ERROR: 'Se produjo un error',
      SUCCESS: 'La operación fue exitosa',
      WARNING: 'Advertencia',
    },
    DETAILED_MESSAGES: {
      INTERNAL_SERVER_ERROR: 'Error interno del servidor',
      REQUEST_ERROR: 'Ha ocurrido un error al hacer la petición',
      ERROR_CONNECTION_LOST: '¡Se ha perdido la conexión con el servidor!',
      WRONG_CREDENTIALS: 'Usuario o contraseña incorrecta',
      REQUEST_NOT_FOUND: '¡No se ha encontrado la solicitud!',
      ERROR_ON_ADD_REQUEST: '¡Ha ocurrido un error al añadir la solicitud!',
      SUCCESS_ON_ADD_REQUEST: '¡Solicitud añadida!',
      ERROR_ON_UPDATE_REQUEST:
        '¡Ha ocurrido un error al actualizar la solicitud!',
      SUCCESS_ON_UPDATE_REQUEST: '¡Solicitud actualizada!',
      SUCCESS_ON_ADD_REQUEST_ITEM: '¡Se ha añadido un producto!',
      SUCCESS_ON_UPDATE_REQUEST_ITEM: '¡Se ha actualizado un producto!',
      SUCCESS_ON_SEND_REQUEST: 'Solicitud enviada con éxito',
      ERROR_ON_SEND_REQUEST: 'No se ha podido enviar la solicitud',
      SUCCESS_ON_DELETE_REQUEST: 'Solicitud eliminada con éxito',
      ERROR_ON_DONWLOAD_QUOTE_LIST_FILE:
        'No se ha podido descargar el archivo seleccionado',
      SUCCESS_ON_SEND_ACCEPTANCE_QUOTE_TO_APPROVER:
        'Hemos recibido la aceptación de la oferta, quando el usuario aprobador la apruebe, en breve podrá consultar su pedido en la sección de pedidos en curso',
      ERROR_ON_SEND_ACCEPTANCE_QUOTE:
        'La oferta no se ha podido aceptar, intente nuevamente',
      SUCCESS_ON_SEND_ACCEPTANCE_QUOTE:
        'Hemos recibido la aceptación de la oferta, en breve podrá consultar su pedido en la sección de pedidos en curso',
      SUCCESS_ON_MODIFY_CECO: 'CECO modificado correctamente',
      ERROR_ON_MODIFY_CECO_VALUE: 'Error al modificar el Ceco: {{value}}',
      ERROR_ON_MODIFY_CECO: 'Error al modificar el Ceco, intente nuevamente',
      SUCCESS_ON_APPROVE_QUOTE: 'Hemos recibido la aprobación de la oferta',
      ERROR_ON_APPROVE_QUOTE:
        'La oferta no se ha podido aprobar, intente nuevamente',
      SUCCESS_ON_NOT_APPROVE_QUOTE:
        'Hemos recibido la no aprobación de la oferta',
      ERROR_ON_NOT_APPROVE_QUOTE:
        'La oferta no se ha podido no aprobar, intente nuevamente',
      ORDER_NOT_FOUND: '¡No se ha encontrado el pedido!',
      ERROR_ON_DOWNLOAD_INVOICE_CREDIT_MEMO:
        'Error al descargar la factura/abono',
      ERROR_REQUEST_ITEM_IS_BLOCKED:
        'Un producto de la solicitud está bloqueado, llame a su gestor',
    },
    ACTIONS: {
      OK: 'Aceptar',
      CLOSE: 'Cerrar',
    },
  },
  COMPONENTS: {
    AG_GRID_NO_ROWS: 'No hay filas para mostrar',
    POPUP_MODIFY_CECO: {
      LABEL: 'Ceco seleccionado: {{value}}',
      SEARCH_INPUT: 'Buscar ceco',
      TABLE: {
        HEADERS: {
          CECO: 'CECO',
          DESCRIPTION: 'Descripción',
        },
        BLANK_RECORD: 'En blanco',
      },
      ACTIONS: {
        ACCEPT: 'Aceptar',
        CANCEL: 'Cancelar',
      },
    },
  },
  SERVICES: {
    CONFIRM: {
      ACTIONS: {
        ACCEPT: 'Aceptar',
        CANCEL: 'Cancelar',
      },
      DEFAULT_VALUES: {
        TITLE: 'Confirmación',
        MESSAGE: '¿Está seguro?',
        INPUT_TEXT: 'Número de pedido (si lo tiene)',
      },
    },
    LOADER: {
      TITLE: 'Espere por favor',
    },
  },
  AUTH: {
    SIGNIN: {
      TITLE: 'Acceso al área de clientes',
      SIGNIN_BTN: 'Acceder',
      USERNAME: 'Usuario',
      PASSWORD: 'Contraseña',
    },
    CHANGE_PASSWORD: {
      TITLE: 'Ingrese la información requerida para cambiar tu contraseña',
      FORM: {
        LABELS: {
          CURRENT_PASSWORD: 'Contraseña actual',
          NEW_PASSWORD: 'Nueva contraseña',
          REPEAT_NEW_PASSWORD: 'Repita nueva contraseña',
        },
        ERRORS: {
          NEW_PASSWORD_REQUIRED: 'Nueva contraseña es obligatoria',
          REPEAT_NEW_PASSWORD_REQUIRED: 'Repetir contraseña es obligatorio.',
          REPEAT_NEW_PASSWORD_MISMATCH:
            'No es igual a nueva contraseña, verifique por favor.',
        },
        BUTTONS: {
          CHANGE: 'Cambiar',
          CANCEL: 'Cancelar cambio',
        },
      },
      SNACKBAR: {
        wrongPassword:
          'Contraseña actual debe ser la misma contraseña que hoy posee el usuario',
        newPasswordNotMatch:
          'Contraseña nueva y repetir contraseña deben ser iguales',
      },
    },
  },
  PROFILE: {
    INFO: {
      TITLE: 'Información del usuario',
      LABELS: {
        NAME: 'Nombre',
        LAST_NAME: 'Apellido',
        EMAIL: 'Correo electrónico',
        PHONE: 'Teléfono',
        LANGUAGE: 'Idioma',
      },
      DEFAULT_LABELS: {
        LANGUAGE: 'Idioma por defecto',
      },
    },
  },
  HEADER: {
    USER_MENU: {
      SIGN_OUT: 'Salir',
      PROFILE: 'Perfil',
      CHANGE_PASSWORD: 'Cambiar contraseña',
    },
    HELP_MENU: {
      DOWNLOAD_MANUAL: 'Descargar manual',
    },
    BUTTONS: {
      NEW_REQUEST: 'Nueva solicitud',
      UPLOAD_MANUAL: 'Subir manual',
    },
    NAVIGATION: {
      HOME: 'Inicio',
      NEW_REQUEST: 'Nueva solicitud',
      REQUESTS_IN_PROGRESS: 'Solicitudes en curso',
      QUOTES_IN_PROGRESS: 'Ofertas en curso',
      ORDERS_IN_PROGRESS: 'Pedidos en curso',
      ORDER_HISTORY: 'Histórico de pedidos',
      INVOICE_HISTORY: 'Histórico de facturas',
      ORDER_LINES: 'Líneas de pedido',
    },
    TOOLTIPS: {
      HELP_MENU: 'Ayuda',
      USER_MENU: 'Configuración de la cuenta',
      BUTTON_NEW_REQUEST: 'Abrir una nueva solicitud',
      BUTTON_UPLOAD_MANUAL: 'Adjuntar manual',
      BUTTON_TOGGLE_MENU: 'Alternar ocultar/mostrar',
    },
  },
  HOME: {
    WELCOME_TITLE: 'Bienvenido, {{name}}',
    TILES: {
      TITLES: {
        DOCUMENTS: 'Documentos',
        APPROVALS: 'Aprobaciones',
      },
      DOCUMENTS: {
        QUOTE_REQUESTS: 'Solicitudes de oferta',
        QUOTES_IN_PROGRESS: 'Ofertas en curso',
        ORDERS_IN_PROGRESS: 'Pedidos en curso',
      },
      APPROVALS: {
        QUOTES_PENDING_APPROVAL: 'Tus ofertas pend. de aprob.',
        TO_APPROVE: 'Para aprobar',
      },
    },
  },
  REQUEST: {
    EDIT_REQUEST: {
      TITLE: 'Solicitud de oferta',
      SUBTITLE: 'Información solicitud: {{value}}',
      BUTTONS: {
        BACK: 'Volver',
        SEND: 'Enviar solicitud',
        SAVE: 'Guardar solicitud',
        DELETE: 'Eliminar solicitud',
        ADD_ITEM: 'Añadir productos',
      },
      FORM: {
        LABELS: {
          DATE: 'Fecha',
          REQUEST_TYPE: 'Tipo solicitud',
          COMPANY: 'Empresa',
          USER_REQUESTING: 'Usuario que solicita',
          YOUR_REFERENCE: 'Su referencia',
          CECO: 'Ceco',
          REPAIR: 'Reparación',
          COMMENT: 'Comentario',
        },
        CONTROLS: {
          MIN_LENGTH: 'Este campo requiere al menos {{value}} caracteres',
          MAX_LENGTH:
            'Este campo no puede contener mas de {{value}} caracteres',
        },
        TITLES: {
          OPEN_CECO_LIST: 'Abrir lista de CECOS',
        },
      },
      TABLE: {
        TITLE: 'Lista de productos',
        TOTAL_IMPORT: 'Importe total: ',
        ACTIONS: {
          MODIFY: 'Modificar',
          DELETE: 'Eliminar',
          SEE: 'Ver',
        },
        HEADERS: {
          DESCRIPTION: 'Material/Servicio',
          UNIT_PRICE: 'Precio unitario',
          QUANTITY: 'Cantidad',
          TOTAL: 'Total',
          FILES: 'Archivos',
          ACTIONS: 'Acciones',
        },
      },
      DIALOGS: {
        ITEM_PTPC: {
          ADD_ITEMS: 'Añadir productos',
          UPDATE_ITEMS: 'Actualizar productos',
          SEE_ITEMS: 'Ver productos',
        },
        SEND_REQUEST: {
          MESSAGE: '¿Confirma que desea enviar la solicitud?',
          TITLE: 'Envio de solicitud',
        },
        DELETE_REQUEST: {
          MESSAGE: '¿Confirma que desea eliminar la solicitud?',
          TITLE: 'Eliminar solicitud',
        },
        DELETE_REQUEST_ITEM: {
          MESSAGE: '¿Confirma que desea eliminar el producto?',
          TITLE: 'Eliminar producto',
        },
      },
    },
    EDIT_REQUEST_DETAIL: {
      FORM: {
        LABELS: {
          DESCRIPTION_ITEM: 'Descripción del producto',
          QUANTITY: 'Cantidad',
          UNIT_PRICE: 'Precio unitario',
          ALLOW_ALTERNATIVES: 'Permitir alternativas',
        },
        BUTTONS: {
          OPEN_CATALOG_TOOL_TIP: 'Abrir catálogo de productos',
        },
      },
      FILES: {
        ATTACH_FILE: 'Adjuntar archivo',
        MAX_SIZE: '({{value}} Tamaño máximo)',
        NO_FILES: 'No se ha adjuntado ningún archivo',
        PROGRESS: 'Progreso:',
        TOOL_TIPS: {
          DELETE: 'Eliminar archivo',
          DOWNLOAD: 'Descargar archivo',
        },
        ON_UPLOAD_ERRORS: {
          ERROR: 'Error subiendo archivo {{value}}',
          MAX_SIZE:
            '¡Cuidado! El archivo "{{fileName}}" es de {{size}}, excede el tamaño máximo permitido de {{maxSize}}',
        },
        ON_DELETE_FILE: {
          MESSAGE: '¿Confirma que desea eliminar el archivo?',
          TITLE: 'Eliminar archivo',
        },
      },
      DIALOG_ACTIONS: {
        ACCEPT: 'Aceptar',
        CANCEL: 'Cancelar',
      },
    },
    ITEM_PTPC: {
      LABEL: 'Producto seleccionado: {{value}}',
      SEARCH_INPUT: 'Buscar producto',
      TABLE: {
        HEADERS: {
          CODE: 'Código',
          DESCRIPTION: 'Descripción',
          VENDOR_REFERENCE: 'Referencia proveedor',
        },
      },
      ACTIONS: {
        ACCEPT: 'Aceptar',
        CANCEL: 'Cancelar',
      },
    },
    LIST_REQUEST: {
      TITLE: 'Solicitudes de oferta',
      SEARCH_INPUT: 'Buscar solicitud',
      TABLE: {
        REQUEST_NO: 'Nº solicitud',
        CECO: 'Ceco',
        COMPANY: 'Empresa',
        DATE: 'Fecha',
        REQUESTED_BY: 'Solicitado por',
        EXTERNAL_REFERENCE: 'Referencia externa',
        STATUS: 'Estado',
        IMPORT: 'Importe',
      },
    },
  },
  QUOTE: {
    LIST: {
      TITLE: 'Ofertas',
      SEARCH_INPUT: 'Buscar oferta',
      CLEAN_FILTERS: 'Limpiar filtros',
      TABLE: {
        QUOTE_NO: 'Nº oferta',
        CECO: 'Ceco',
        COMPANY: 'Empresa',
        REQUEST_DATE: 'Fecha solicitud',
        REQUESTED_BY: 'Solicitado por',
        EXTERNAL_REFERENCE: 'Referencia externa',
        QUOTE_STATUS: 'Estado Oferta',
        APPROVAL_STATUS: 'Estado de Aprobación',
        IMPORT: 'Importe',
        DOWNLOAD: 'Descargar',
      },
      TABLE_ACTIONS: {
        DOWNLOAD: 'Descargar',
      },
    },
    DETAIL: {
      TITLE: 'Oferta',
      BUTTONS: {
        APPROVE_THE_QUOTE: 'Aprobar la oferta',
        NOT_APPROVE_THE_QUOTE: 'No aprobar la oferta',
        MODIFY_CECO: 'Modificar CECO',
        BACK: 'Volver',
        ACCEPT_QUOTE: 'Aceptar oferta',
      },
      TOOL_TIPS: {
        APPROVE_THE_QUOTE: 'Aprobar la oferta',
        NOT_APPROVE_THE_QUOTE: 'No aprobar la oferta',
        MODIFY_CECO: 'Modificar CECO',
        BACK: 'Ir a la página anterior',
      },
      LABELS: {
        QUOTE_NO: 'Nº oferta',
        REQUESTED_BY: 'Solicitado por',
        YOUR_REFERENCE: 'Su referencia',
        STATUS: 'Estado',
        CECO: 'Ceco',
        APPROVAL_STATUS: 'Estado de aprobación',
        DIRECT_ORDER: 'Pedido directo',
        COMMENT: 'Comentario',
      },
      TABLE: {
        TITLE: 'Lista de productos',
        ACTIONS: {
          YES: 'Sí',
          NO: 'No',
        },
        HEADERS: {
          DESCRIPTION: 'Material/Servicio',
          QUANTITY: 'Cantidad',
          IMPORT: 'Importe',
          STATUS: 'Estado',
          ACCEPTED_LINE: 'Línea aceptada',
        },
        TOTAL_IMPORT: 'Total importe: ',
      },
      DIALOGS: {
        ACCEPT: {
          TITLE: 'Aceptar oferta',
          MESSAGE: '¿Desea aceptar la oferta?',
          INPUT_TEXT: 'Número de Pedido (Si tiene)',
        },
        SELECT_APPROVER: {
          TITLE: 'Seleccione el usuario aprobador',
          MESSAGE:
            'Aviso: Esta oferta requiere una aprobación interna para su tramitación. Se envía solicitud de aprobación al usuario:',
          INPUT_TEXT: 'Usuario aprobador',
        },
        APPROVE: {
          TITLE: 'Aprobar oferta',
          MESSAGE: '¿Desea aprobar la oferta?',
        },
        NOT_APPROVE: {
          TITLE: 'No aprobar oferta',
          MESSAGE: '¿Desea no aprobar la oferta?',
        },
      },
    },
  },
  ORDER: {
    ORDER_LIST: {
      TITLE: 'Pedidos',
      HISTORY_TITLE: 'Histórico de pedidos',
      SEARCH_INPUT: 'Buscar pedido',
      TABLE: {
        HEADERS: {
          ORDER_NO: 'Nº pedido',
          CECO: 'Ceco',
          COMPANY: 'Empresa',
          ORDER_DATE: 'Fecha pedido',
          REQUESTED_BY: 'Solicitado por',
          EXTERNAL_REFERENCE: 'Referencia externa',
          STATUS: 'Estado',
          EXTERNAL_DOCUMENT_NO: 'Nº documento externo',
          IMPORT: 'Importe',
          QUOTE: 'Oferta',
        },
      },
    },
    DETAIL: {
      TITLE: 'Pedido',
      BUTTONS: {
        BACK: 'Volver',
      },
      LABELS: {
        ORDER_NO: 'Nº pedido',
        ORDER_DATE: 'Fecha pedido',
        REQUEST_NO: 'Nº solicitud',
        CECO: 'Ceco',
        QUOTE_NO: 'Nº oferta',
        EXTERNAL_DOCUMENT_NO: 'Nº documento externo',
        EXTERNAL_REFERENCE: 'Referencia externa',
        COMMENT: 'Comentario',
      },
      TABLE: {
        TITLE: 'Lista de productos',
        HEADERS: {
          DESCRIPTION: 'Material/Servicio',
          QUANTITY: 'Cantidad',
          QUANTITY_SENT: 'Cant. enviada',
          OUTSTANDING_QUANTITY: 'Cdad. pendiente',
          IMPORT: 'Importe',
          STATUS: 'Estado',
          EXPECTED_DEADLINE_DATE: 'Fecha plazo previsto',
        },
        TOTAL_IMPORT: 'Total importe: ',
      },
    },
    INVOCIE_CREDIT_MEMO: {
      TITLE: 'Facturas y abonos',
      SEARCH_INPUT: 'Buscar factura/abono',
      TABLE: {
        INVOICE_CREDIT_NO: 'Nº factura/abono',
        DATE: 'Fecha',
        IMPORT: 'Importe',
        TYPE: 'Tipo',
        DOWNLOAD: 'Descargar',
      },
      TABLE_ACTIONS: {
        DOWNLOAD: 'Descargar',
      },
      LINE_TYPE: {
        INVOICE: 'Factura',
        CREDIT_MEMO: 'Abono',
      },
    },
    ORDER_LINES_LIST: {
      TITLE: 'Líneas de pedido',
      SEARCH_INPUT: 'Buscar línea de pedido',
      TABLE: {
        HEADERS: {
          ORDER_DATE: 'Fecha pedido',
          CLARIFICATION_ORDER_DATE: 'Fecha aclaración pedido',
          QUOTE_NO: 'Nº oferta',
          ORDER_NO: 'Nº pedido',
          COMPANY: 'Empresa',
          EXTERNAL_REFERENCE: 'Referencia externa',
          EXTERNAL_DOCUMENT_NO: 'Nº documento externo',
          CUSTOMER_REFERENCE: 'Referencia cliente',
          DESCRIPTION: 'Material/Servicio',
          REQUESTED_QTY: 'Cant. solicitada',
          SHIPPED_QTY: 'Cant. enviada',
          OUTSTANDING_QTY: 'Cant. pendiente',
          EXPECTED_DELIVERY_DATE: 'Fecha plazo previsto',
          WORK_TYPE: 'Motivo pdte. plazo',
          SELL_TO_CONTACT: 'Solicitante',
          CECO: 'Ceco',
        },
      },
      EXCEL: {
        ORDER_DATE: 'Fecha pedido',
        CLARIFICATION_ORDER_DATE: 'Fecha aclaración pedido',
        QUOTE_NO: 'Nº oferta',
        ORDER_NO: 'Nº pedido',
        COMPANY: 'Empresa',
        EXTERNAL_REFERENCE: 'Referencia externa',
        EXTERNAL_DOCUMENT_NO: 'Nº documento externo',
        CUSTOMER_REFERENCE: 'Referencia cliente',
        DESCRIPTION: 'Material/Servicio',
        REQUESTED_QTY: 'Cant. solicitada',
        SHIPPED_QTY: 'Cant. enviada',
        OUTSTANDING_QTY: 'Cant. pendiente',
        EXPECTED_DELIVERY_DATE: 'Fecha plazo previsto',
        WORK_TYPE: 'Motivo pdte. plazo',
        SELL_TO_CONTACT: 'Solicitante',
        CECO: 'Ceco',
      },
    },
  },
};

export default translations;
